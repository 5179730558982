import React from 'react';
import './LoginPage.css';

interface LoginPageProps {
    onSignIn: () => void;
}

const LoginPage: React.FC<LoginPageProps> = ({ onSignIn }) => {
    return (
        <div className="login-page">
            <div className="login-content">
                <div className="brand-header">
                    <span className="brand-my">my</span>
                    <span className="brand-guys">GUYS</span>
                </div>
                
                <h1>Create Amazing AI Characters</h1>
                
                <div className="features">
                    <div className="feature">
                        <span className="feature-icon">✨</span>
                        <h3>Generate Characters</h3>
                        <p>Create unique characters in various styles including Funko Pop, Lego, Disney, and more</p>
                    </div>
                    
                    <div className="feature">
                        <span className="feature-icon">🎬</span>
                        <h3>Animate Your Creations</h3>
                        <p>Bring your characters to life with AI-powered animations</p>
                    </div>
                    
                    <div className="feature">
                        <span className="feature-icon">🌟</span>
                        <h3>Share & Discover</h3>
                        <p>Share your creations and discover characters made by others</p>
                    </div>
                </div>

                <button className="login-button" onClick={onSignIn}>
                    <svg width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2">
                        <path d="M15 3h4a2 2 0 0 1 2 2v14a2 2 0 0 1-2 2h-4" />
                        <polyline points="10 17 15 12 10 7" />
                        <line x1="15" y1="12" x2="3" y2="12" />
                    </svg>
                    Sign in with Google
                </button>
                
                <p className="login-note">
                    Note: Access is currently limited. To join the waitlist, {' '}
                    <a href="mailto:support@myguys.site">email support@myguys.site</a>
                </p>
            </div>
        </div>
    );
};

export default LoginPage; 