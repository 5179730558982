import React, { useEffect } from 'react';
import { ImagePrompt } from '../types';
import { getCachedImageUrl } from '../constants/clientConstants';

interface ImagePreloaderProps {
    prompts: ImagePrompt[];
    currentIndex: number;
    preloadCount: number;
}

const ImagePreloader: React.FC<ImagePreloaderProps> = ({ prompts, currentIndex, preloadCount }) => {
    useEffect(() => {
        // Preload next few images
        const preloadImages = () => {
            const startIdx = currentIndex;
            const endIdx = Math.min(startIdx + preloadCount, prompts.length);
            
            for (let i = startIdx; i < endIdx; i++) {
                const prompt = prompts[i];
                if (prompt) {
                    // Create new image object to preload
                    const img = new Image();
                    img.src = getCachedImageUrl(prompt.id);
                    
                    // Fallback to original URL if cached isn't available
                    img.onerror = () => {
                        if (prompt.imageUrl) {
                            img.src = prompt.imageUrl;
                        }
                    };
                }
            }
        };

        preloadImages();
    }, [prompts, currentIndex, preloadCount]);

    return null; // This component doesn't render anything
};

export default ImagePreloader; 