import { create } from 'zustand';
import { ImagePrompt } from '../types';
import { doc, deleteDoc, collection, limit, orderBy, query, startAfter, where, getDocs, getDoc } from 'firebase/firestore';
import { db } from '../firebase';
import { IMAGE_PROMPT_COLLECTION, CARDS_PER_PAGE } from '../constants/clientConstants';
import { User } from 'firebase/auth';
import { getAuth, onAuthStateChanged, signOut } from 'firebase/auth';
import { onSnapshot } from 'firebase/firestore';

interface PromptState {
  prompts: ImagePrompt[];
  lastDocRef: any;
  user: User | null;
  currentIndex: number;
  
  setCurrentIndex: (index: number) => void;
  setUser: (user: User | null) => void;
  setPrompts: (prompts: ImagePrompt[] | ((prev: ImagePrompt[]) => ImagePrompt[])) => void;
  addPrompts: (newPrompts: ImagePrompt[]) => void;
  addPrompt: (prompt: ImagePrompt) => void;
  deletePrompt: (id: string) => void;
  setLastDocRef: (ref: any) => void;
  clearPrompts: () => void;
  slideDirection: 'forward' | 'backward';
  setSlideDirection: (direction: 'forward' | 'backward') => void;
  goToNext: () => void;
  goToPrevious: () => void;
  fetchMoreIfNeeded: () => void;
  isScrolling: boolean;
  setIsScrolling: (value: boolean) => void;
  isLoading: boolean;
  currentFilter: 'all' | 'my' | 'top' | 'recent' | 'oldest';
  setCurrentFilter: (filter: 'all' | 'my' | 'top' | 'recent' | 'oldest') => void;
  fetchPrompts: (lastDocument?: any) => Promise<void>;
  initialId: string | null;
  setInitialId: (id: string | null) => void;
  fetchInitialPrompt: (id: string) => Promise<void>;
  insertPrompt: (prompt: ImagePrompt, afterId?: string) => void;
  goToIndex: (index: number) => void;
  coins: number;
  setCoins: (coins: number) => void;
  initAuth: () => void;
  signOut: () => Promise<void>;
  isAdmin: boolean;
}

export const deduplicatePrompts = (prompts: ImagePrompt[]): ImagePrompt[] => {
  const seen = new Set<string>();
  return prompts.filter(prompt => {
      if (seen.has(prompt.id)) {
          return false;
      }
      seen.add(prompt.id);
      return true;
  });
};

export const usePromptStore = create<PromptState>((set, get) => ({
  prompts: [],
  lastDocRef: null,
  user: null,
  currentIndex: 0,
  setCurrentIndex: (index) => set({ currentIndex: index }),
  setUser: (user) => set({ user }),
  setPrompts: (prompts) => set((state) => ({ 
    prompts: typeof prompts === 'function' ? prompts(state.prompts) : prompts 
  })),
  addPrompts: (newPrompts) => set((state) => ({ 
    prompts: [...state.prompts, ...newPrompts] 
  })),
  addPrompt: (prompt) => set((state) => ({
    prompts: [prompt, ...state.prompts]
  })),
  deletePrompt: (id) => {
    deleteDoc(doc(db, IMAGE_PROMPT_COLLECTION, id))
      .then(() => {
        set((state) => {
          const deleteIndex = state.prompts.findIndex(p => p.id === id);
          const newPrompts = state.prompts.filter(p => p.id !== id);
          
          // If deleting current or earlier prompt, adjust currentIndex
          const newIndex = deleteIndex <= state.currentIndex - 1 
            ? Math.max(0, state.currentIndex - 1)
            : state.currentIndex;

          return {
            prompts: newPrompts,
            currentIndex: newIndex
          };
        });
      })
      .catch(error => console.error('Error deleting document:', error));
  },
  setLastDocRef: (ref) => set({ lastDocRef: ref }),
  clearPrompts: () => set({ prompts: [], lastDocRef: null, currentIndex: 0 }),
  slideDirection: 'forward',
  setSlideDirection: (direction) => set({ slideDirection: direction }),
  goToNext: () => {
    const { currentIndex, prompts, fetchMoreIfNeeded } = get();
    console.log('goToNext', currentIndex, prompts.length);
    
    // Don't check isScrolling here
    fetchMoreIfNeeded();
    
    set(state => ({ 
        slideDirection: 'forward',
        currentIndex: Math.min(currentIndex + 1, prompts.length)
    }));
  },
  goToPrevious: () => {
    const { currentIndex } = get();
    
    set({ 
        slideDirection: 'backward',
        currentIndex: Math.max(currentIndex - 1, 0)
    });
  },
  fetchMoreIfNeeded: () => {
    const { prompts, currentIndex, user, lastDocRef, fetchPrompts } = get();
    const PRELOAD_COUNT = 5;
    
    if (currentIndex >= prompts.length - (PRELOAD_COUNT + 1) && user) {
      fetchPrompts(lastDocRef);
    }
  },
  isScrolling: false,
  setIsScrolling: (value) => set({ isScrolling: value }),
  isLoading: false,
  currentFilter: 'recent',
  setCurrentFilter: (filter) => set({ currentFilter: filter }),

  fetchPrompts: async (lastDocument?: any) => {
    const { isLoading, user, currentFilter, lastDocRef } = get();
    
    if (isLoading || !user) return;
    
    set({ isLoading: true });

    try {
      let q = query(
        collection(db, IMAGE_PROMPT_COLLECTION),
        limit(CARDS_PER_PAGE)
      );

      switch (currentFilter) {
        case 'my':
          q = query(q, where('userId', '==', user.uid), orderBy('createdAt', 'desc'));
          break;
        case 'top':
          q = query(
            collection(db, IMAGE_PROMPT_COLLECTION),
            orderBy('createdAt', 'desc'),
            orderBy('votes', 'desc'),
            limit(CARDS_PER_PAGE)
          );
          break;
        case 'recent':
          q = query(q, orderBy('createdAt', 'desc'));
          break;
        case 'oldest':
          q = query(q, orderBy('createdAt', 'desc'));
          break;
      }

      if (lastDocRef) {
        q = query(q, startAfter(lastDocRef));
      }

      const querySnapshot = await getDocs(q);
      const lastVisible = querySnapshot.docs[querySnapshot.docs.length - 1];
      
      const newPrompts = querySnapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data(),
        createdAt: doc.data().createdAt?.toDate()
      })) as ImagePrompt[];

      set((state) => ({
        prompts: [...state.prompts, ...newPrompts],
        lastDocRef: lastVisible,
        isLoading: false
      }));

    } catch (error) {
      console.error('Error fetching prompts:', error);
      set({ isLoading: false });
    }
  },

  initialId: null,
  setInitialId: (id) => set({ initialId: id }),

  fetchInitialPrompt: async (id: string) => {
    try {
      const docRef = doc(db, IMAGE_PROMPT_COLLECTION, id);
      const docSnap = await getDoc(docRef);

      if (docSnap.exists()) {
        const initialPrompt = {
          id,
          ...docSnap.data(),
          createdAt: docSnap.data().createdAt?.toDate()
        } as ImagePrompt;

        set((state) => ({
          prompts: deduplicatePrompts([initialPrompt, ...state.prompts])
        }));

        set((state) => ({
          currentIndex: state.prompts.findIndex(prompt => prompt.id === id) + 1
        }));
      }
    } catch (error) {
      console.error('Error fetching initial prompt:', error);
    }
  },

  insertPrompt: (prompt: ImagePrompt, afterId?: string) => {
    set((state) => {
      const newPrompts = [...state.prompts];
      
      if (afterId) {
        // Find index of specified ID
        const insertIndex = newPrompts.findIndex(p => p.id === afterId);
        if (insertIndex !== -1) {
          newPrompts.splice(insertIndex + 1, 0, prompt);
        } else {
          newPrompts.unshift(prompt);
        }
      } else {
        // Insert at current index
        const insertIndex = Math.max(0, state.currentIndex);
        newPrompts.splice(insertIndex, 0, prompt);
      }

      return {
        prompts: deduplicatePrompts(newPrompts)
      };
    });
  },

  goToIndex: (index: number) => {
    const { prompts, currentIndex } = get();
    const targetIndex = Math.max(0, Math.min(index, prompts.length));
    const direction = targetIndex > currentIndex ? 'forward' : 'backward';
    
    set({ 
        slideDirection: direction,
        currentIndex: targetIndex 
    });
  },

  isAdmin: false,
  coins: 0,
  setCoins: (coins) => set({ coins }),

  initAuth: () => {
    const auth = getAuth();
    const unsubscribe = onAuthStateChanged(auth, (currentUser) => {
      set({ user: currentUser });

      // If user is logged in, subscribe to their document
      if (currentUser) {
        const userDocRef = doc(db, 'users', currentUser.uid);
        const unsubscribeDoc = onSnapshot(userDocRef, (docSnap) => {
          if (docSnap.exists()) {
            set({ 
              isAdmin: docSnap.data()?.isAdmin || false,
              coins: docSnap.data()?.coins || 0 });
          }
        }, (error) => {
          console.error('Error listening to user document:', error);
        });

        // Return cleanup function that unsubscribes from both auth and doc
        return () => {
          unsubscribe();
          unsubscribeDoc();
        };
      }

      // If no user, just return auth unsubscribe
      return () => unsubscribe();
    });
  },

  signOut: async () => {
    const auth = getAuth();
    try {
      await signOut(auth);
      set({ 
        user: null, 
        prompts: [], 
        lastDocRef: null, 
        currentIndex: 0,
        coins: 0
      });
    } catch (error) {
      console.error('Error signing out:', error);
    }
  },
})); 