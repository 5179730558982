import { getAuth, GoogleAuthProvider, onAuthStateChanged, signInWithPopup } from 'firebase/auth';
import { doc, onSnapshot, Timestamp } from 'firebase/firestore';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import Card from './components/Card';
import CardCharacterForm from './components/CardCharacterForm';
import CardDialog from './components/CardDialog';
import CharacterCard from './components/CharacterCard';
import ImagePreloader from './components/ImagePreloader';
import LoginPage from './components/LoginPage';
import MainCard from './components/MainCard';
import MainHeader from './components/MainHeader';
import { StyleType } from './constants/styles';
import { db } from './firebase';
import './Main.css';
import { usePromptStore } from './stores/promptStore';

interface MainProps {
}

const CARD_BACKGROUNDS = ['#1e2326', '#343941']; // Darkened first color by 25%
const SCROLL_DEBOUNCE = 800; // Reduce from 800 to match animation time
const SCROLL_THRESHOLD = 10; // Minimum scroll/swipe distance
const PRELOAD_COUNT = 5;

const Main: React.FC<MainProps> = () => {
    const { 
        prompts, 
        clearPrompts, 
        user, 
        deletePrompt,
        currentIndex,
        slideDirection,
        goToNext,
        goToPrevious,
        isScrolling,
        setIsScrolling,
        fetchPrompts,
        setCurrentFilter,
        currentFilter,
        setInitialId,
        fetchInitialPrompt,
        isLoading,
        initAuth
    } = usePromptStore();
    const [isCreateDialogOpen, setIsCreateDialogOpen] = useState(false);
    const [selectedCharacter, setSelectedCharacter] = useState<{
        id: string;
        prompt: string;
        style: StyleType;
        createdAt: Timestamp;
        userName: string | null;
        votes: number;
        videoStatus?: string;
        videoError?: string;
        visibility?: 'PUBLIC' | 'PRIVATE';
        xId?: string;
        xError?: string;
        instagramId?: string;
        instagramError?: string;
        youtubeId?: string;
        youtubeError?: string;
    } | null>(null);
    const touchStartY = useRef(0);
    const containerRef = useRef<HTMLDivElement>(null);
   
    // Initialize auth on mount
    useEffect(() => {
        initAuth();
    }, [initAuth]);

    // Update URL check effect
    useEffect(() => {
        const params = new URLSearchParams(window.location.search);
        const id = params.get('id');
        if (id) {
            setInitialId(id);
            fetchInitialPrompt(id);
        }
    }, [setInitialId, fetchInitialPrompt]);

    // Update filter change effect
    useEffect(() => {
        clearPrompts();
        fetchPrompts();
    }, [currentFilter, clearPrompts, fetchPrompts]);

    // Update initial load effect
    useEffect(() => {
        if (prompts.length === 0 && user) {
            fetchPrompts();
        }
    }, [prompts.length, user, fetchPrompts]);

    const onSignIn = async () => {
        const auth = getAuth();
        const provider = new GoogleAuthProvider();
        
        // Add domain restriction
        provider.setCustomParameters({
            'hd': 'northcircle.net'  // Hosted domain parameter
        });

        try {
            const result = await signInWithPopup(auth, provider);
            // Verify email domain
            const email = result.user.email;
            if (!email?.endsWith('@northcircle.net')) {
                await auth.signOut();
                alert('Only northcircle.net email addresses are allowed');
                return;
            }
        } catch (error: any) {
            if (error.code === 'auth/invalid-email') {
                alert('Only northcircle.net email addresses are allowed');
            } else {
                console.error('Error signing in:', error);
            }
        }
    };

    const handleDeletePrompt = useCallback((deletedId: string) => {
        deletePrompt(deletedId);
    }, [deletePrompt]);

    const handleNewPrompt = useCallback(async (newPromptId: string) => {
        try {
            fetchInitialPrompt(newPromptId);
        } catch (error) {
            console.error('Error fetching new prompt:', error);
        }
    }, [fetchInitialPrompt]);

    // 2. Memoize handlers
    const handleImageClick = useCallback(async (id: string) => {
        await fetchInitialPrompt(id);
    }, [fetchInitialPrompt]);

    const handleTouchStart = useCallback((e: React.TouchEvent<HTMLDivElement> | TouchEvent) => {
        const touch = e instanceof TouchEvent ? e.touches[0] : e.touches[0];
        touchStartY.current = touch.clientY;
    }, []);

    const handleTouchEnd = useCallback((e: React.TouchEvent<HTMLDivElement> | TouchEvent) => {
        console.log('handleTouchEnd', isScrolling);
        if (isScrolling) return;
        
        const touch = e instanceof TouchEvent ? e.changedTouches[0] : e.changedTouches[0];
        const deltaY = touchStartY.current - touch.clientY;
        
        if (Math.abs(deltaY) > SCROLL_THRESHOLD) {
            setIsScrolling(true);
            
            if (deltaY > 0) {
                goToNext();
            } else {
                goToPrevious();
            }

            setTimeout(() => {
                setIsScrolling(false);
            }, SCROLL_DEBOUNCE);
        }
    }, [goToNext, goToPrevious, isScrolling, setIsScrolling]);

    // Update keyboard handler
    useEffect(() => {
        const handleKeyPress = (e: KeyboardEvent) => {
            if (e.key === 'ArrowDown') goToNext();
            if (e.key === 'ArrowUp') goToPrevious();
        };

        window.addEventListener('keydown', handleKeyPress);
        return () => window.removeEventListener('keydown', handleKeyPress);
    }, [goToNext, goToPrevious]);

    // Add this effect to handle initial load
    useEffect(() => {
        if (prompts.length === 0 && !isLoading && user) {
            fetchPrompts();
        }
    }, [fetchPrompts, prompts.length, isLoading, user]);

    return (
        <>
            {!user ? (
                <LoginPage onSignIn={onSignIn} />
            ) : (
                <>
                    <div className="card-container" ref={containerRef}>
                        <button 
                            className="nav-button prev-button" 
                            onClick={goToPrevious}
                            disabled={currentIndex === 0}
                        >
                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2">
                                <polyline points="18 15 12 9 6 15" />
                            </svg>
                        </button>

                        <div 
                            className="card-wrapper"
                            onWheel={(e) => {
                                if (isScrolling) return;
                                
                                const scrollAmount = Math.abs(e.deltaY);
                                if (scrollAmount < SCROLL_THRESHOLD) return;
                                
                                setIsScrolling(true);
                                if (e.deltaY > 0) {
                                    goToNext();
                                } else {
                                    goToPrevious();
                                }
                                
                                setTimeout(() => setIsScrolling(false), SCROLL_DEBOUNCE);
                            }}
                            onTouchStart={(e) => {
                                touchStartY.current = e.touches[0].clientY;
                            }}
                            onTouchEnd={(e) => {
                                if (isScrolling) return;
                                
                                const deltaY = touchStartY.current - e.changedTouches[0].clientY;
                                if (Math.abs(deltaY) < SCROLL_THRESHOLD) return;
                                
                                setIsScrolling(true);
                                if (deltaY > 0) {
                                    goToNext();
                                } else {
                                    goToPrevious();
                                }
                                
                                setTimeout(() => setIsScrolling(false), SCROLL_DEBOUNCE);
                            }}
                        >
                            <TransitionGroup>
                                <CSSTransition
                                    key={currentIndex}
                                    timeout={300}
                                    classNames={`slide${slideDirection === 'backward' ? '-reverse' : ''}`}
                                >
                                    {currentIndex === 0 ? (
                                        <MainCard
                                            setIsCreateDialogOpen={setIsCreateDialogOpen}
                                            onImageClick={handleImageClick}
                                        />
                                    ) : (
                                        <Card
                                            background={CARD_BACKGROUNDS[currentIndex % 2]}
                                            id={prompts[currentIndex - 1]?.id || ''}
                                        >
                                            <CharacterCard
                                                id={prompts[currentIndex - 1]?.id || ''}
                                                imagePrompt={prompts[currentIndex - 1]}
                                                user={user}
                                                onDelete={handleDeletePrompt}
                                                onShowDetails={setSelectedCharacter}
                                            />
                                        </Card>
                                    )}
                                </CSSTransition>
                            </TransitionGroup>
                        </div>

                        <button 
                            className="nav-button next-button" 
                            onClick={goToNext}
                            disabled={currentIndex >= prompts.length}
                        >
                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2">
                                <polyline points="6 9 12 15 18 9" />
                            </svg>
                        </button>
                    </div>

                    <MainHeader
                        isSignedIn={!!user}
                        onSignIn={onSignIn}
                        setIsCreateDialogOpen={setIsCreateDialogOpen}
                        setFilter={setCurrentFilter}
                        currentFilter={currentFilter}
                        selectedCharacter={selectedCharacter}
                        onCloseCharacterDetails={() => setSelectedCharacter(null)}
                    />
                    <CardDialog
                        isOpen={isCreateDialogOpen}
                        onClose={() => setIsCreateDialogOpen(false)}
                        background="#1a1e24"
                    >
                        <div className="create-dialog-content">
                            <h1>Create Your Character</h1>

                            <CardCharacterForm
                                onSubmit={(prompt, style, newPromptId) => {
                                    if (newPromptId) {
                                        handleNewPrompt(newPromptId);
                                    }
                                }}
                                user={user}
                                onClose={() => setIsCreateDialogOpen(false)}
                            />

                        </div>
                    </CardDialog>

                    <ImagePreloader 
                        prompts={prompts}
                        currentIndex={currentIndex}
                        preloadCount={PRELOAD_COUNT}
                    />
                </>
            )}
        </>
    );
};

export default Main; 