import React from 'react';
import './ImageRibbon.css';
import images from '../components/images.json';
interface ImageRibbonProps {
    count?: number;
    onImageClick?: (id: string) => void;
}

const ImageRibbon: React.FC<ImageRibbonProps> = ({ onImageClick }) => {
    const prompts = images;
    return (
        <div className="image-ribbon">
            <div className="ribbon-content">
                {[...prompts].map((prompt, index) => {


                    const id = prompt.split('/')[1].replace('.jpg', '');
                    return <img
                        key={index}
                        src={prompt }
                        alt=""
                        className="ribbon-image"
                        onClick={() => onImageClick?.(id)}
                        style={{ cursor: 'pointer' }}
                    />
                    }
                )}
            </div>
        </div>
    );
};

export default ImageRibbon; 