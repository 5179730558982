export type StyleType = 'funko' | 'bobble' | 'potato' | 'barbie' | 'lego' | 'gijoe' | 'cabbage' |
    'littlepeople' | 'polly' | 'transformers' | 'americangirl' | 'portraits' |
    'carebear' | 'playmobile' | 'matryoshka' | 'smurf' | 'pixar' | 'dreamworks' | 'disney' |
    'ragdoll' | 'heman' | 'animalcrossing' | 'nintendo' | 'pokemon' | 'crossyroad' | 'minion' | 'custom' | 'realistic' | 'anime' | 'beanie' | 'buildabear' |  'minecraft' |
    'photorealistic' | 'muppet' | 'steampunk' | 'cyberpunk' | 'comicbook' | 'nutcracker' | 
    'starwars' | 'superherocomic' | 'landscapes' | 'origami' | 'castles' | 'roblox';

export interface StyleConfig {
    name: string;
    prompt: string;
    style: StyleType;
    animationPrompt: string;
    negativePrompt: string;
}

export const negativePrompt = '  .Do not include any branding, or watermarks in the image.';
export const animationPrompt = 'The subject(s) smile and dance';
export const styleConfigs: Record<StyleType, StyleConfig> = {
    roblox:{
        name: 'Roblox',
        style: 'roblox',
        prompt: 'Style = This is a scene from a 3-d animated video where all characters are Roblox figures. The video\'s artistic style features rich detail, vibrant colors, dramatic backdrops, an epic cinematic feel and incredible lighting. ',
        animationPrompt: animationPrompt,
        negativePrompt: negativePrompt
    },
    funko: {
        name: 'Funko Pop',
        style: 'funko',
        prompt: 'Style = This is a still from a 3-d animated video that takes place in a Funko-Pop universe. The video\'s artistic style features rich detail, vibrant colors, dramatic backdrops, an epic cinematic feel and incredible lighting. ',
        animationPrompt: animationPrompt,
        negativePrompt: negativePrompt
    },
    cabbage: {
        name: 'Cabbage Patch',
        style: 'cabbage',
        prompt: 'Style = This is a still from a Cabbage Patch Kids animated video where all characters have the iconic soft, round-faced doll aesthetic. The style features chubby cheeks, yarn-like hair, dimpled smiles, soft fabric textures, and the characteristic innocent, childlike Cabbage Patch Kid appearance with warm, cozy lighting and a nostalgic feel.',
        animationPrompt: animationPrompt,
        negativePrompt: negativePrompt
    },
    barbie: {
        name: 'Barbie Doll',
        style: 'barbie',
        prompt: 'Style = This is a still from a Barbie video where all characters have the iconic Barbie doll aesthetic. The style features perfect plastic shine, articulated joints, fashionable outfits, and the signature Barbie pink color palette with a modern cinematic feel.',
        animationPrompt: animationPrompt,
        negativePrompt: negativePrompt
    },
    lego: {
        name: 'Lego Mini Fig',
        style: 'lego',
        prompt: 'Style = This is a still from a Lego Video where all characters are Lego minifigures.  Everyting in the image is made of Lego bricks. The style features the iconic Lego aesthetic with plastic texture, cylindrical hands, c-grip hands, stud connections, and the characteristic Lego minifigure proportions and movements.',
            animationPrompt: animationPrompt,
        negativePrompt: negativePrompt
    },
    bobble: {
        name: 'Bobble Head',
        style: 'bobble',
        prompt: 'Style = This is a still from a 3-d animated video where all characters are bobblehead figures with oversized heads that wobble. The style features exaggerated proportions, springy movement, and playful expressions.',
        animationPrompt: animationPrompt,
        negativePrompt: negativePrompt
    },
    potato: {
        name: 'Potato Head',
        style: 'potato',
        prompt: 'Style = This is a still from a 3-d animated video where all characters are Mr. Potato Head style toys. The characters have detachable parts, plastic texture, and classic toy-like appearance with a modern twist.',
        animationPrompt: animationPrompt,
        negativePrompt: negativePrompt
    },

    gijoe: {
        name: 'GI Joe Figure',
        style: 'gijoe',
        prompt: 'Style = This is a still from a GI Joe animated video where all characters are realistic military action figures. The style features detailed military uniforms, articulated joints, realistic weapons and gear, rugged textures, and the classic GI Joe action figure aesthetic with dramatic military-style lighting and atmosphere.',
        animationPrompt: animationPrompt,
        negativePrompt: negativePrompt
    },


    littlepeople: {
        name: 'Fisher Price',
        style: 'littlepeople',
        prompt: 'Style = This is a still from a Fisher Price Little People animated video. The style features chunky, rounded shapes, simple but expressive faces, bright primary colors, and the classic Little People toy aesthetic with soft, friendly lighting and a playful atmosphere.',
        animationPrompt: animationPrompt,
        negativePrompt: negativePrompt
    },

    polly: {
        name: 'Polly Pocket',
        style: 'polly',
        prompt: 'Style = This is a still from a Polly Pocket animated video. The style features miniature scale, fashion-forward designs, pearlescent finishes, cute accessories, and the signature Polly Pocket compact aesthetic with sparkly, magical lighting effects.',
        animationPrompt: animationPrompt,
        negativePrompt: negativePrompt
    },

    transformers: {
        name: 'Transformer',
        style: 'transformers',
        prompt: 'Style = This is a still featuring a Transformers video.  All characters are Transformers action figures with complex mechanical details, metallic surfaces, visible transformation hinges and panels, robotic features, and the iconic Transformers toy aesthetic with dramatic, sci-fi lighting.',
        animationPrompt: animationPrompt,
        negativePrompt: negativePrompt
    },

    americangirl: {
        name: 'American Girl',
        style: 'americangirl',
        prompt: 'Style = This is a still featuring an American Girl doll video.  All characters are American Girl dolls with highly detailed period-accurate clothing, realistic hair and facial features, articulated limbs, and the signature American Girl quality and craftsmanship with warm, natural lighting.',
        animationPrompt: animationPrompt,
        negativePrompt: negativePrompt
    },

    carebear: {
        name: 'Care Bear',
        style: 'carebear',
        prompt: 'Style = This is a still from a Care Bears animated video. The style features soft, plush teddy bear characters with round bodies, heart-shaped noses, colorful fur, and magical belly badges. The aesthetic includes pastel colors, rainbow effects, fluffy cloud environments, and a warm, magical glow with sparkles and hearts in the atmosphere.',
        animationPrompt: animationPrompt,
        negativePrompt: negativePrompt
    },

    playmobile: {
        name: 'Playmobil',
        style: 'playmobile',
        prompt: 'Style = This is a still from a Playmobil animated video. The style features characters with the iconic Playmobil aesthetic - simple cylindrical bodies, c-grip hands, round heads with minimal facial features, plastic sheen, and characteristic helmet/hair pieces that are molded on. The style includes clean lines, bright colors, and the classic toy-like appearance with slightly glossy surfaces and warm lighting.',
        animationPrompt: animationPrompt,
        negativePrompt: negativePrompt
    },

    matryoshka: {
        name: 'Matryoshka',
        style: 'matryoshka',
        prompt: 'Style = This is a still from a video where all characters are traditional Russian Matryoshka nesting dolls. The style features the iconic rounded, egg-like shape, ornate folk art patterns and floral designs, bright traditional Russian colors, hand-painted details, and the characteristic rosy-cheeked face with a warm, friendly expression. The doll has a glossy lacquered finish and traditional Russian folk art decorative elements.',
        animationPrompt: animationPrompt,
        negativePrompt: negativePrompt
    },

    smurf: {
        name: 'Smurf',
        style: 'smurf',
        prompt: 'Style = This is a still from a video where all characters are Smurfs. The style features the iconic blue skin, short stature (3 apples tall), round features, white clothing and hat, and the characteristic Smurf proportions with oversized head and nose. The character has the classic Smurf village aesthetic with mushroom houses, forest elements, and the warm, magical atmosphere typical of Smurf stories.',
        animationPrompt: animationPrompt,
        negativePrompt: negativePrompt
    },

    pixar: {
        name: 'Pixar',
        style: 'pixar',
        prompt: 'Style = This is a still from a Pixar animated video. The style features the iconic Pixar aesthetic with highly detailed 3D rendering, expressive faces with exaggerated features, subtle texturing, emotional depth, and cinematic lighting. The character has the signature Pixar attention to small details, surface materials, and a perfect blend of realism with cartoonish charm.',
        animationPrompt: animationPrompt,
        negativePrompt: negativePrompt
    },

    dreamworks: {
        name: 'DreamWorks',
        style: 'dreamworks',
        prompt: 'Style = This is a still from a DreamWorks animated video. The style features the iconic DreamWorks animation aesthetic with exaggerated expressions, dynamic poses, stylized realism, and dramatic lighting. The character has the signature DreamWorks look with slightly caricatured features, expressive eyes, smooth texturing, and a blend of humor and emotion in their design.',
        animationPrompt: animationPrompt,
        negativePrompt: negativePrompt
    },

    disney: {
        name: 'Disney',
        style: 'disney',
        prompt: 'Style = This is a still from a  Disney animated video. The style features the iconic Disney animation aesthetic with fluid movements, expressive faces, soft rounded features, and enchanting character design. The character has the signature Disney look with large emotive eyes, graceful proportions, and a magical, timeless quality enhanced by warm, rich colors and ethereal lighting.',
        animationPrompt: animationPrompt,
        negativePrompt: negativePrompt
    },

    ragdoll: {
        name: 'Rag Doll',
        style: 'ragdoll',
        prompt: 'Style = This is a still from a video where all characters are handmade rag dolls. The style features soft fabric textures, visible stitching, button eyes, yarn hair, patchwork clothing, and the characteristic handcrafted appearance of traditional rag dolls. The doll has a charming, rustic quality with a mix of different fabric patterns and textures, giving it a cozy, homemade feel with warm, gentle lighting.',
        animationPrompt: animationPrompt,
        negativePrompt: negativePrompt
    },

    heman: {
        name: 'He-Man',
        style: 'heman',
        prompt: 'Style = This is a still from a video where all characters are Masters of the Universe action figures in the classic He-Man toy style. The character has exaggerated muscular proportions, bold primary colors, detailed armor and weapons, and the characteristic vintage 1980s action figure aesthetic with dramatic heroic poses. The figure has that distinctive plastic sheen, articulated joints, and the iconic chunky, durable design of the original MOTU toy line with dramatic fantasy-style lighting.',
        animationPrompt: animationPrompt,
        negativePrompt: negativePrompt
    },

    animalcrossing: {
        name: 'Animal Crossing',
        style: 'animalcrossing',
        prompt: 'Style = This is a still from a video where all characters are in the style of Animal Crossing. The characters have a cute, chibi-like appearance with a large round head, small body, and simple facial features. The art style is soft and cartoony with bright, cheerful colors. The character has the characteristic Animal Crossing proportions with stubby limbs and a friendly, innocent expression. The overall aesthetic matches the cozy, wholesome feel of Animal Crossing games with smooth, rounded shapes and a warm, inviting atmosphere.',
        animationPrompt: animationPrompt,
        negativePrompt: negativePrompt
    },

    nintendo: {
        name: 'Nintendo',
        style: 'nintendo',
        prompt: 'Style = This is a still from a video where all characters are in classic Nintendo art style. Characters have the iconic Nintendo polish with bright, vibrant colors and clean, appealing designs reminiscent of Super Mario characters. The style features rounded, friendly shapes, expressive faces, and the characteristic Nintendo charm with smooth textures and playful proportions. The lighting is bright and cheerful with subtle cel-shading effects.',
        animationPrompt: animationPrompt,
        negativePrompt: negativePrompt
    },

    pokemon: {
        name: 'Pokemon',
        style: 'pokemon',
        prompt: 'Style = This is a still from a video where all characters are in the style of Pokemon. The characters have the distinctive Pokemon aesthetic with clean lines, bright colors, and anime-inspired features. The design is cute and appealing with large expressive eyes, simple but memorable shapes, and the characteristic Pokemon art style that balances realism with cartoonish charm. The character has a friendly, approachable appearance with smooth cel-shaded rendering and bold outlines.',
        animationPrompt: animationPrompt,
        negativePrompt: negativePrompt
    },

    crossyroad: {
        name: 'Crossy Road',
        style: 'crossyroad',
        prompt: 'Style = This is a still from a video where all characters are in the style of Crossy Road voxel art style. The characters have a blocky, cubic design with minimal details and bold colors. The aesthetic features chunky voxel-based shapes, simple geometric forms, and charming pixel-art textures. The character has the characteristic Crossy Road look with a boxy head, stubby limbs, and playful, minimalist features. The style includes subtle ambient occlusion and flat color surfaces with a clean, modern voxel art appearance.',
        animationPrompt: animationPrompt,
        negativePrompt: negativePrompt
    },

    minion: {
        name: 'Minion',
        style: 'minion',
        prompt: 'Style = This is a still from a video where all characters are in the style of a Minion from Despicable Me. The characters have the iconic Minion appearance with a short, cylindrical body, blue overalls, and large goggled eyes. The style features the characteristic yellow skin tone, simple but expressive features, and the playful, mischievous energy of Minions. The character maintains the cute, comedic appeal with rounded shapes and a cartoonish quality that makes Minions instantly recognizable.',
        animationPrompt: animationPrompt,
        negativePrompt: negativePrompt
    },

    custom: {
        name: 'Custom',
        style: 'custom',
        prompt: negativePrompt,
        animationPrompt: animationPrompt,
        negativePrompt: negativePrompt
    },

    realistic: {
        name: 'Realistic',
        style: 'realistic',
        prompt: 'Style = This is a still from a video where all characters are in the style of a photorealistic style. The characters have highly detailed features with natural proportions and textures. The rendering is lifelike with accurate lighting, shadows, and materials that create a convincing sense of depth and dimension. The style emphasizes realism in every aspect, from skin texture to clothing fabric, making the character appear as if they could exist in the real world. The overall aesthetic is grounded in reality while maintaining artistic excellence in composition and detail.',
        animationPrompt: animationPrompt,
        negativePrompt: negativePrompt
    },

    anime: {
        name: 'Anime',
        style: 'anime',
        prompt: 'Style = This is a still from a video where all characters are in the style of Japanese anime art style. The characters feature large expressive eyes, vibrant hair, and exaggerated facial features typical of anime. The design incorporates clean lines, dynamic poses, and a distinctive cel-shaded look with bold outlines. The style captures the essence of modern anime aesthetics with attention to details like hair highlights, dramatic expressions, and stylized proportions that are characteristic of Japanese animation.',
        animationPrompt: animationPrompt,
        negativePrompt: negativePrompt
    },

    beanie: {
        name: 'Beanie Baby',
        style: 'beanie',
        prompt: 'Style = This is a still from a video where all characters are in the style of a Ty Beanie Baby plush toy. The characters have the signature soft, plush appearance with large sparkly button eyes and a rounded, cuddly form. The design features the characteristic Beanie Baby proportions with a slightly oversized head, stubby limbs, and a plump body. The texture appears soft and fuzzy like a plush toy, with visible stitching details and the iconic heart-shaped Ty tag. The overall aesthetic captures the cute, collectible nature of Beanie Babies with their endearing expressions and huggable design.',
        animationPrompt: animationPrompt,
        negativePrompt: negativePrompt
    },

    buildabear: {
        name: 'Build-A-Bear',
        style: 'buildabear',
        prompt: 'Style = This is a still from a video where all characters are in the style of a Build-A-Bear Workshop plush toy. The characters have the signature Build-A-Bear look with a plump, huggable body and soft, fluffy fur texture. The design features the characteristic heart-shaped nose, embroidered details, and customizable clothing elements typical of Build-A-Bear creations. The overall aesthetic captures the personalized, high-quality craftsmanship with attention to details like stitching, accessory placement, and the warm, inviting appearance that Build-A-Bear Workshop is known for.',
        animationPrompt: animationPrompt,
        negativePrompt: negativePrompt
    },

    minecraft: {
        name: 'Minecraft',
        style: 'minecraft',
        prompt: 'Style = This is a still from a video where all characters are in the style of the distinctive Minecraft blocky art style. The characters are built entirely from cubic blocks with pixelated textures, following Minecraft\'s signature 16x16 pixel aesthetic. The design features sharp, geometric shapes with no curves, maintaining the game\'s iconic voxel-based look. Colors are simple and solid, with minimal shading, and the overall appearance captures the beloved blocky, retro-style graphics that define Minecraft\'s visual identity.',
        animationPrompt: animationPrompt,
        negativePrompt: negativePrompt
    },

    photorealistic: {
        name: 'Photo Realistic',
        style: 'photorealistic',
        prompt: 'Style = This is a still from a video where all characters are in the style of a hyper-realistic photographic style. The image should look indistinguishable from a high-end professional photograph, with perfect lighting, depth of field, and ultra-fine detail. Every element should exhibit photographic qualities including natural skin textures, fabric weave patterns, subtle reflections, and precise shadow casting. The rendering should include photographic elements like subtle lens effects, bokeh in out-of-focus areas, and the kind of micro-details you\'d expect from a DSLR camera with a high-quality lens.',
        animationPrompt: animationPrompt,
        negativePrompt: negativePrompt
    },

    muppet: {
        name: 'Muppet',
        style: 'muppet',
        prompt: 'Style = This is a still from a video where all characters are in the style of Jim Henson\'s Muppets. The characters have the distinctive Muppet appearance with a soft, fuzzy texture and vibrant colors. The design features the characteristic large, expressive eyes, wide mouth, and exaggerated features typical of Muppet characters. The style incorporates visible fabric textures, felt-like materials, and the playful, energetic personality that Muppets are known for. The overall aesthetic captures the whimsical, hand-crafted quality of classic Muppet characters with their unique blend of charm and character.',
        animationPrompt: animationPrompt,
        negativePrompt: negativePrompt
    },

    steampunk: {
        name: 'Steampunk',
        style: 'steampunk',
        prompt: 'Style = This is a still from a video where all characters are in the style of Victorian Steampunk. The aesthetic includes intricate gears, steam-powered machinery, vintage industrial elements, and ornate Victorian decorations. The style combines antique elements with mechanical innovations, featuring rich bronze and copper tones, weathered metals, leather textures, and elaborate clockwork mechanisms. The lighting suggests gas lamps and steam, creating a warm, vintage industrial atmosphere with brass highlights and mechanical details.',
        animationPrompt: animationPrompt,
        negativePrompt: negativePrompt
    },

    cyberpunk: {
        name: 'Cyberpunk',
        style: 'cyberpunk',
        prompt: 'Style = futuristic cyberpunk style. The aesthetic features neon-lit environments, high-tech elements, and a dark urban atmosphere. The style combines advanced technology with a gritty, dystopian feel, featuring holographic displays, neon signs, chrome and metal textures, and digital glitch effects. The lighting emphasizes stark contrasts between deep shadows and bright neon colors, particularly electric blues, hot pinks, and acid greens. The scene includes cybernetic enhancements, advanced interfaces, and the characteristic mix of high technology with urban decay that defines the cyberpunk genre.',
        animationPrompt: animationPrompt,
        negativePrompt: negativePrompt
    },

    comicbook: {
        name: 'Comic Book',
        style: 'comicbook',
        prompt: 'Style = This is a still from a video where all characters are in the style of classic comic book art style. The design features bold outlines, dramatic shading with halftone dots, vibrant primary colors, and dynamic comic book aesthetics. The style incorporates characteristic comic art elements like strong contrasts, exaggerated expressions, and action-oriented poses. The character has the iconic comic book look with cel-shaded coloring, dramatic shadows, and that distinctive vintage comic print quality with subtle halftone patterns and bold ink work reminiscent of classic superhero comics.',
        animationPrompt: animationPrompt,
        negativePrompt: negativePrompt
    },
    nutcracker: {
        name: 'Nutcracker',
        style: 'nutcracker',
        prompt: 'Style = This is a still from a video where all characters are in the style of a traditional German nutcracker doll. The design features ornate military-style uniforms with gold braiding, decorative buttons, and a tall ceremonial hat. The character has the iconic wooden nutcracker aesthetic with a large head, prominent jaw mechanism, detailed painted features, and stiff, formal posture. The style incorporates rich colors like deep reds, royal blues, and metallic golds, with intricate uniform details and decorative elements. The overall look captures the regal, festive quality of classic nutcracker dolls with their characteristic blend of military formality and Christmas charm.',
        animationPrompt: animationPrompt,
        negativePrompt: negativePrompt
    },

 
    starwars: {
        name: 'Star Wars',
        style: 'starwars',
        prompt: 'Style = This is a still from a video where all characters are in the style of  Star Wars. The design features the iconic sci-fi aesthetic with detailed armor or robes, distinctive alien features, and advanced technology. The character has the characteristic Star Wars look with a mix of lived-in technology and mystical elements, incorporating design elements from both the Republic and Empire eras. The style includes weathered metallic surfaces, glowing energy elements, and the blend of ancient and futuristic that defines Star Wars, with dramatic lighting that suggests either the light or dark side of the Force.',
        animationPrompt: animationPrompt,
        negativePrompt: negativePrompt
    },

    superherocomic: {
        name: 'Superhero Comic',
        style: 'superherocomic',
        prompt: 'Style = This is a still from a video where all characters are in the style of  classic superhero comic book style from the Silver Age of Comics. The design features bold, dynamic poses with strong outlines and dramatic shading using Ben-Day dots pattern. The character has exaggerated heroic proportions, vibrant primary colors, and iconic superhero costume elements. The style incorporates dramatic foreshortening, action-oriented composition, and the characteristic comic book color palette of the 1960s. The overall look captures the energetic, larger-than-life quality of vintage superhero comics with halftone effects, bold shadows, and that distinctive hand-drawn comic book aesthetic.',
        animationPrompt: animationPrompt,
        negativePrompt: negativePrompt
    },


    landscapes: {
        name: 'Landscapes',
        style: 'landscapes',
        prompt: 'You are a landscape photographer. Create a landscape photo that is beautiful and unique.  There should be NOT be any people in the image.',
        animationPrompt: 'Sweeping camera shot. Epic cinematic style.',
        negativePrompt: negativePrompt
    },
    origami: {
        name: 'Origami',
        style: 'origami',
        prompt: 'Style = This is a still from a video where all characters are in the style of origami. The design features intricate folds, sharp angles, and minimalist details. The character has the characteristic origami aesthetic with a flat, geometric form, sharp edges, and the characteristic origami look with a mix of sharp angles and subtle gradients. The style incorporates subtle gradients, soft shadows, and the playful, friendly appearance typical of origami art. The overall look captures the cute, accessible nature of origami with clean lines, bold shapes, and that instantly recognizable digital-friendly design.',
        animationPrompt: animationPrompt,
        negativePrompt: negativePrompt
    },
    portraits: {
        name: 'Portraits',
        style: 'portraits',
        prompt: 'You are a portrait photographer.  This is a character captured in a hyper-realistic photographic style. The image should look indistinguishable from a high-end professional photograph, with perfect lighting, depth of field, and ultra-fine detail. Every element should exhibit photographic qualities including natural skin textures, fabric weave patterns, subtle reflections, and precise shadow casting. The rendering should include photographic elements like subtle lens effects, bokeh in out-of-focus areas, and the kind of micro-details you\'d expect from a DSLR camera with a high-quality lens.',
        animationPrompt: 'suject looks at the camera and makes a facial expression.',
        negativePrompt: negativePrompt
    },
    castles: {
        name: 'Castles',
        style: 'castles',
        prompt: 'You are a landscape photographer. Create a landscape photo that is beautiful and unique.  There should be NOT be any people in the image.',
        animationPrompt: 'Sweeping camera shot. Epic cinematic style.',
        negativePrompt: negativePrompt
    }
};

export const getSelectedStyle = (selectedStyle: StyleType): StyleConfig => {
    return styleConfigs[selectedStyle] || styleConfigs['funko'];
};