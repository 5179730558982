import React, { useState } from 'react';
import './ImageContainer.css';

interface ImageContainerProps {
    imageUrl: string;
    cachedImageUrl?: string;
    videoUrl?: string;
    showVideo?: boolean;
    prompt?: string;
}

const ImageContainer = React.forwardRef<HTMLImageElement, ImageContainerProps>(({
    imageUrl,
    cachedImageUrl,
    videoUrl,
    showVideo,
    prompt
}, ref) => {
    const [isLoading, setIsLoading] = useState(true);
    const displayVideo = videoUrl && showVideo;
    
    return (
        <div className="character-image-container">
            {isLoading && (
                <div className="loading-spinner">
                    <svg width="24" height="24" viewBox="0 0 24 24" stroke="currentColor">
                        <circle cx="12" cy="12" r="10" fill="none" strokeWidth="2" />
                        <path d="M12 2a10 10 0 0 1 10 10" strokeWidth="2" />
                    </svg>
                </div>
            )}
            {displayVideo ? (
                <video
                    key={videoUrl}
                    src={videoUrl}
                    autoPlay
                    loop
                    muted
                    playsInline
                    className={`character-video ${isLoading ? 'loading' : ''}`}
                    onLoadedData={() => setIsLoading(false)}
                />
            ) : (
                <img
                    ref={ref}
                    key={imageUrl}
                    src={cachedImageUrl}
                    alt={prompt}
                    onError={(e) => {
                        const target = e.target as HTMLImageElement;
                        if (target.src !== cachedImageUrl) {
                            target.src = imageUrl;
                        }
                    }}
                    className={`character-image ${isLoading ? 'loading' : ''}`}
                    onLoad={() => setIsLoading(false)}
                />
            )}
        </div>
    );
});

export default ImageContainer; 